import { DATE_FORMAT } from '@/app/consts/app';
import { AmountFormatter, CurrencyCodeGetterById } from '@/app/types/common';
import { formatTime } from '@/app/utils/dates';
import IconButtonWithTooltip from '@/components/IconButtonWithTooltip';
import StatusBadge from '@/components/StatusBadge';
import { FileStatusEnum, InboxRecordSchema } from '@/generated';
import InboxRowMoreButton from '@/pages/backoffice/Inbox/InboxList/InboxRowMoreButton';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { ImageCarouselModalData } from '@/pages/backoffice/Modals/command';
import { COLOURS } from '@core-ui/styles';
import { TableCell, TableRow } from '@core-ui/table';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Info } from '@phosphor-icons/react';
import { FormattedMessage } from 'components/Intl';
import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

interface IProps {
  item: InboxRecordSchema;
  amountFormatter: AmountFormatter;
  currencyCodeGetter: CurrencyCodeGetterById;
}

const InboxRow = (props: IProps) => {
  const { item, amountFormatter, currencyCodeGetter } = props;

  const dispatch = useDispatch();
  const styles = useStyles();

  const handleOpenFilePreview = () => {
    const isFilePdf = item.filename?.endsWith('.pdf');
    const previewFiles = isFilePdf ? item.previews?.map((preview) => preview.link) : [item.link];

    if (previewFiles) {
      dispatch(
        openBackofficeModal(
          new ImageCarouselModalData(previewFiles, item.document_id, item.status, item.uploaded_by_id, item.created_at)
        )
      );
    }
  };

  const createdAt = formatTime(item.created_at, { dateFormat: DATE_FORMAT });

  const uploadedByName = [item.uploaded_by_first_name, item.uploaded_by_last_name].filter(Boolean).join(' ') || (
    <FormattedMessage id="label.no_info" />
  );

  const isDocumentReturned = item.status === FileStatusEnum.RETURNED;

  const reasonOfReturn =
    isDocumentReturned && item.reason_of_return
      ? `${item.status}: ${item.reason_of_return}`.replace(/_/g, ' ')
      : `${item.status}`.replace(/_/g, ' ');

  const showReturnedStatusTooltip = Boolean(isDocumentReturned && item.reason_of_return_comment?.trim());
  const currency = item.currency_id ? currencyCodeGetter(Number(item.currency_id)) : '';

  return (
    <TableRow>
      <TableCell onClick={handleOpenFilePreview} sx={styles.nameCell}>
        {item.filename}
      </TableCell>

      <TableCell sx={styles.cell}>{item.vendor}</TableCell>

      <TableCell>{createdAt}</TableCell>

      <TableCell>
        <Grid container gap={2} alignItems="center">
          <StatusBadge status={item.status} label={reasonOfReturn} />

          {showReturnedStatusTooltip && (
            <Tooltip title={<Typography>{item.reason_of_return_comment}</Typography>} placement="top" arrow>
              <SvgIcon htmlColor={COLOURS.HUE_STRAWBERRY.HUE_100}>
                <Info />
              </SvgIcon>
            </Tooltip>
          )}
        </Grid>
      </TableCell>

      <TableCell sx={styles.cell}>{uploadedByName}</TableCell>

      <TableCell>
        <Typography fontFamily="mono">{currency}</Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.total)}
        </Typography>
      </TableCell>

      <TableCell padding="medium">
        <Grid container gap={2} justifyContent="flex-end" alignItems="center">
          <IconButtonWithTooltip
            onClick={handleOpenFilePreview}
            tooltipLabel="label.open_source_file"
            ariaLabel="aria.label.open_file_preview"
          />

          <InboxRowMoreButton file={item} />
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default InboxRow;
